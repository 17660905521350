import React, {Component} from 'react';

class Consultation extends Component {
    render() {
        return (
            <React.Fragment>
                <h1 className="text-center">Coming Soon</h1>
            </React.Fragment>
        );
    }
}

export default Consultation;