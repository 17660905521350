import React, {Component} from 'react';

class TrainingCourse extends Component {
    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
}

export default TrainingCourse;