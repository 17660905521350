import React, {Component} from 'react';
import Particles from "react-particles-js";
import {Link} from "react-router-dom";

class Hashloops extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <Particles
                    style={{backgroundImage: `url(assets/img/hashback.png)`, backgroundPosition: 'center', backgroundSize: 'cover', position: 'absolute'}}
                    height="500px"
                    params={{
                        particles: {
                            number: {
                                value: 80,
                                density: {
                                    enable: true,
                                    value_area: 800,
                                }
                            },
                            line_linked: {
                                "enable": true,
                                "distance": 160,
                                "color": "#cf1429",
                                "opacity": 0.6,
                                "width": 1
                            },
                            shape: {
                                type: "circle",
                                stroke: {
                                    "width": 3,
                                    "color": "#eeeeee"
                                },
                                "polygon": {
                                    "nb_sides": 4
                                },
                                // image: {
                                //     src: 'assets/img/hash.png',
                                //     width: 100,
                                //     height: 100
                                // }
                            },
                            move: {
                                out_mode: "bounce",
                                speed: 5
                            },
                        },
                        "interactivity": {
                            "detect_on": "window",
                            "events": {
                                "onhover": {
                                    "enable": true,
                                    "mode": "grab"
                                },
                                "resize": true
                            },
                            "modes": {
                                "grab": {
                                    "distance": 150,
                                    "line_linked": {
                                        "opacity": 1,
                                        "color": "#001aff",
                                        "width": 2
                                    }
                                },
                                "bubble": {
                                    "distance": 400,
                                    "size": 40,
                                    "duration": 2,
                                    "opacity": 8,
                                    "speed": 6
                                },
                                "repulse": {
                                    "distance": 100,
                                    "duration": 0.4
                                },
                                "push": {
                                    "particles_nb": 4
                                },
                                "remove": {
                                    "particles_nb": 2
                                }
                            }
                        },
                        "retina_detect": true
                    }}
                />

                <div style={{position: 'relative', top: 0, left: 0, right: 0, bottom: 0, margin: '0 auto', height: 500}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="redLine" style={{marginTop: 180}}>
                                    <span style={{ color: 'white'}} className="first">IT Services And Solutions</span>
                                </div>
                                <h2 className="pt-1 text-uppercase firstHeading" style={{ color: '#cf1429', fontSize: 50 }}>A bond giving rise to <br/> new prospects</h2>
                                {/*<h2 className="text-white secondHeading" style={{ fontSize: 24 }}>We are pleased to tell you that hashloops technologies are our it and software consulting partners.</h2>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="categories-area mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-tittle mb-70">
                                    <span>Our Services</span>
                                    <h2>Our Best Services</h2>
                                </div>
                                <p>Hashloops Technologies we focus on delivering end-to-end solutions according to customer requirements. This includes understanding the requirements, planning, developing, deploying, and maintaining/upgrading the solutions we build.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50" style={{ height: 400}}>
                                    <div className="cat-icon">
                                        <span className="fas fa-laptop" style={{fontSize: '5rem'}}></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a>Web Development </a></h5>
                                        <p>Our web development experts can help you build your online shop, your business website, your custom software module using latest technologies like Laravel, Ruby on Rails, Django, Node, React, Angular, Vue, Wordpress, Shopify and many more.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50" style={{ height: 400}}>
                                    <div className="cat-icon">
                                        <span className="fab fa-apple" style={{fontSize: '5rem'}}></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a>IOS Development</a></h5>
                                        <p>We can build iOS applications by focusing on latest trends, technologies, user experience and a high standard of programming.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50" style={{ height: 400}}>
                                    <div className="cat-icon">
                                        <span className="fab fa-android" style={{fontSize: '5rem'}}></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a>Android Development</a></h5>
                                        <p>Our Android development, we can help you to launch your desired applications to Google Play Store. Our team has extensive experience building fast, high performance, and secure mobile applications.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50" style={{height: 540}}>
                                    <div className="cat-icon mb-4 pb-3" style={{width: 80, display: 'inline-block'}}>
                                        <img src="assets/img/social.png" alt="" className="pt-2" style={{width: '100%'}}/>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a>Social Media Marketing(SSM)</a></h5>
                                        <p>If you are looking to expand your business through social media channels, Hashloops can help. Our SMM services will help you increase your business presence to the target audience through Facebook, Instagram and Twitter that gives you more leads for selling your products from internet & increase audience to know your brand through Social Media platform.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50" style={{height: 540}}>
                                    <div className="cat-icon">
                                        <span className="flaticon-development"></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><a>Search Engine Optimization(SEO)</a></h5>
                                        <p>Tell us if you are looking to build an online reputation for your business. We offer one of the best SEO services which can easily increase your online visibility. We have unique strategies and experience in SEO and digital marketing services. With our latest tools and techniques you can grow traffic and increase revenue.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50" style={{height: 540}}>
                                    <div className="cat-icon">
                                        <span className="flaticon-result"></span>
                                    </div>
                                    <div className="cat-cap">
                                        <h5 className="mb-5"><a>Financials - ERP</a></h5>
                                        <p>Talk about ERP, Hashloops Technologies is adept in providing the best ERP solutions to different businesses. We are aware of the changing needs and demands that would aid in making business better efficient in different operations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Hashloops;