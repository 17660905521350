import React, {Component} from 'react';
import {Link} from "react-router-dom";

class RealEstate extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/realEstateBack.jpeg)`}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>Real Estate</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="categories-area section-padding30 pb-0" id="services">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-tittle mb-70">
                                        <span>Real Estate</span>
                                        <h2>Our Real Estate Projects</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <p>Buckingham Consultancy has been offering real estate services to its clients since its establishment. Our staff, both in the UK and Kingdom of Saudi Arabia have good knowledge of the UK real estate market and its requirements. We work as a boutique real estate agency specialising in Sales, Lettings, Acquisitions, Short Term Lets and Advisory</p>

                                    <h3>Sales</h3>
                                    <p>With no obligation our experienced staff will provide you the market evaluation and as we have years of experience in London market, our staff will make sure that accurate evaluation is provided. We have good understanding of what works when it comes to selling the property and the rest can be assured by providing the best price in the quickest time. We have pool of buyers, local and international constantly looking for properties in London and the UK</p>

                                    <h3>Lettings</h3>
                                    <p>Our experienced staff is dedicated to find an exceptional tenants and source suitable properties for them. We have a wide knowledge when it comes to sourcing the properties in the UK and especially in London. We have been sourcing properties for students who come to the UK for educational purposes as well as those who come here for short term holidays or for medical treatment. We understand finding the right property can be a difficult task and this is where we can step in and assist our clients by using our knowledge and presence in the United Kingdom</p>

                                    <h3>Advisory, Investment and Acquisitions</h3>
                                    <p>Our investment advisors proudly assist investors with their deep knowledge of developments and the local area. We try to achieve the maximum yields for our clients and make sure that the best deal is achieved. London is full of new developments and its important to have someone with good knowledge of London and the UK who can make sure that satisfaction of investors is achieved once the deal is done and higher returns are guaranteed.</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon mb-3">
                                            {/*<span className="flaticon-development"></span>*/}
                                            <img src="assets/img/realEstate.jpg" className="w-100" style={{height: 220}} alt=""/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5 className="m-0"><Link to={"/palmdor"} className="m-0">مشروع المجمع السكني Palm Dor المغرب - طنجة </Link></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon mb-3">
                                            {/*<span className="flaticon-development"></span>*/}
                                            <img src="assets/img/hero/BPS.jpg" className="w-100" style={{height: 220}} alt=""/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5 className="m-0"><Link to={"/bps"} className="m-0">مشروع شقق باترسي Battersea
                                                بريطانيا - لندن </Link></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon mb-3">
                                            {/*<span className="flaticon-development"></span>*/}
                                            <img src="assets/img/hornsey.jpg" className="w-100" style={{height: 220}} alt=""/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5 className="m-0"><Link to={"/hornsey"} className="m-0">مشروع شقق هورنسي Hornsey
                                                بريطانيا - لندن</Link></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon mb-3">
                                            {/*<span className="flaticon-development"></span>*/}
                                            <img src="assets/img/lutonMain.png" className="w-100" style={{height: 220}} alt=""/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5 className="m-0"><Link to={"/luton"} className="m-0">Luton مشروع استثماري شمال لندن</Link></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default RealEstate;