import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Master from "./components/userside/master";

class App extends Component{
  render() {
    return (
      <Router>
          <Route path={"/"} component={Master} />
      </Router>
    );
  }
}

export default App;
