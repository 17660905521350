import React, {Component} from 'react';

class StudentVisa extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/studentVisa.jpg)`, backgroundPosition: 'center'}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>Student Visa</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-details mt-5">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="offset-xl-1 col-lg-8">
                                    <div className="about-details-cap mb-50">
                                        <h4>Student Visa</h4>

                                        <p>The United Kingdom is well known for its high standard education system with some of the world’s high ranked universities such as Cambridge University, Oxford University and University College London. British universities also have reputation of world class research and UK higher education certificates and degrees are recognised by employers and academics internationally</p>

                                        <h5>What is Tier 4 (General) Student Visa?</h5>
                                        <p>Tier 4 (General) student visa allows a student to enter and study in the United Kingdom and can be applied by anyone who is 16 years old or above In order to apply for Tier 4 (General) student visa, you must be able to demonstrate:</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Have been offered a place on a course in college or university</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Able to speak, read, write and understand English</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Able to fund and support your living expenses and course</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Not European Economic Area (EEA) or Switzerland citizen</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Meet the requirements</p>

                                        <p>You can apply 3 months before the start date of course and decision can take up to 3 weeks</p>

                                        <h5>FEES</h5>
                                        <p>The fees for Tier 4 (General) student visa is £348 from outside the United Kingdom and £348 per person for Dependents
                                        Health Surcharge were introduced in the United Kingdom for non-citizen of European Economic Area (EEA) in April 2015 and its £300 for a student and dependents usually pay the same amount as student</p>

                                        <h5>ELIGIBILTY</h5>

                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>An unconditional offer for a course from a licensed Tier 4 sponsor</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Enough funds to support yourself and the course</p>

                                        <h5>CONFIRMATION OF ACCEPTANCE FOR STUDIES (CAS)</h5>
                                        <p>The Confirmation of Acceptance for Studies also known as CAS number, is provided by education provider once the place has been offered. CAS number is required to be entered on visa application while applying. Tier 4 should be applied with 6 months of receiving the CAS number.</p>

                                        <h5>KNOWLEDGE OF ENGLISH</h5>
                                        <p>Student must be able to prove their knowledge of English. If you have studied in the UK as Tier (Child) student, you are exempt from this requirement. The Nationals of below countries are also exempt from providing their knowledge of English.</p>

                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Antigua and Barbuda</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Australia</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>the Bahamas</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Barbados</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Belize</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Canada</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Dominica</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Grenada</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Guyana</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Ireland</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Jamaica</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>New Zealand</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>St Kitts and Nevis</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>St Lucia</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>St Vincent and the Grenadines</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Trinidad and Tobago</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>UK</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>USA</p>

                                        <h5>DOCUMENTS</h5>

                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Current Passport or other valid travel documentation</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Blank page on passport</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Proof of supporting yourself financially</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Proof of parental or other legal guardian consent will be required if under 18</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Proof of relationship will be required with parents or legal guardian if under 18</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Tuberculosis test results will be required if from a country where you have to take the test</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Addition documents depending on circumstances</p>

                                        <h5>Dependent Visa</h5>
                                        <p>Family members (dependents) can apply and join their partners in the United Kingdom if they are from outside the European Economic Area or Switzerland. Husband, wife and civil partner and child under 18 and ones who are born in the UK during stay will fall in this category
                                        Student’s must be able to prove that they can support the dependents and each dependent must have a certain amount of funds available tot them. The amount of funds varies, and it depends on length of the course and depends where you are studying in the United Kingdom
                                        Proof of funds will be required and should have been in the bank for 28 days before applying for dependent visa. If it’s a form of student loan or official sponsor then funds do not need to be available for 28 days, but confirmation will be required when it will be available</p>

                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Visa Type</th>
                                                <th>Fees</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Student Visa</td>
                                                <td>£348</td>
                                            </tr>
                                            <tr>
                                                <td>Dependent visa</td>
                                                <td>£348 each individual</td>
                                            </tr>
                                            <tr>
                                                <td>Healthcare Surcharge</td>
                                                <td>Differs case to case</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default StudentVisa;