import React, {Component} from 'react';
import {Link} from "react-router-dom";

class Education extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/education.jpg)`, backgroundPosition: 'center'}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>Education</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="categories-area section-padding30">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-tittle mb-70">
                                        <span>Education</span>
                                        <h2>Our Best Educational Services</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon">
                                            <span className="flaticon-development"></span>
                                        </div>
                                        <div className="cat-cap">
                                            <h5><Link to={"/englishLanguage"}>English Language </Link></h5>
                                            <p>There are many variations of passages of lorem Ipsum available but the
                                                new majority have suffered.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon">
                                            <span className="flaticon-result"></span>
                                        </div>
                                        <div className="cat-cap">
                                            <h5><Link to={"/university"}>University</Link></h5>
                                            <p>There are many variations of passages of lorem Ipsum available but the
                                                new majority have suffered.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon">
                                            <span className="flaticon-team"></span>
                                        </div>
                                        <div className="cat-cap">
                                            <h5><Link to={"/trainingCourse"}>Training Courses</Link></h5>
                                            <p>There are many variations of passages of lorem Ipsum available but the
                                                new majority have suffered.</p>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="col-lg-4 col-md-6 col-sm-6">*/}
                                {/*    <div className="single-cat text-center mb-50">*/}
                                {/*        <div className="cat-icon">*/}
                                {/*            <span className="flaticon-result"></span>*/}
                                {/*        </div>*/}
                                {/*        <div className="cat-cap">*/}
                                {/*            <h5><a href="services.html">Insurance Service</a></h5>*/}
                                {/*            <p>There are many variations of passages of lorem Ipsum available but the*/}
                                {/*                new majority have suffered.</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="col-lg-4 col-md-6 col-sm-6">*/}
                                {/*    <div className="single-cat text-center mb-50">*/}
                                {/*        <div className="cat-icon">*/}
                                {/*            <span className="flaticon-team"></span>*/}
                                {/*        </div>*/}
                                {/*        <div className="cat-cap">*/}
                                {/*            <h5><a href="services.html">Audit & Evaluation</a></h5>*/}
                                {/*            <p>There are many variations of passages of lorem Ipsum available but the*/}
                                {/*                new majority have suffered.</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="col-lg-4 col-md-6 col-sm-6">*/}
                                {/*    <div className="single-cat text-center mb-50">*/}
                                {/*        <div className="cat-icon">*/}
                                {/*            <span className="flaticon-development"></span>*/}
                                {/*        </div>*/}
                                {/*        <div className="cat-cap">*/}
                                {/*            <h5><a href="services.html">Strategy Planning </a></h5>*/}
                                {/*            <p>There are many variations of passages of lorem Ipsum available but the*/}
                                {/*                new majority have suffered.</p>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default Education;