import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Layouts from "./views/partials/layouts";
import Main from "./views/main";
import About from "./views/about";
import Contact from "./views/contact";
import RealEstate from "./views/realEstate";
import Consultation from "./views/consultation";
import Education from "./views/education";
import VisaAndImmigration from "./views/visaAndImmigration";
import StudentVisa from "./views/studentVisa";
import GeneralVisa from "./views/generalVisa";
import SettlementVisa from "./views/settlementVisa";
import EnglishLanguage from "./views/englishLanguage";
import University from "./views/university";
import TrainingCourse from "./views/trainingCourse";
import Blog from "./views/blog";
import Hashloops from "./views/hashloops";
import Palmdor from "./views/palmdor";
import Bps from "./views/Bps";
import Hornsey from "./views/Hornsey";
import Luton from "./views/luton";

class Master extends Component {
    render() {
        return (
            <Router>
                <Layouts>
                    <Route path={"/"} component={Main}>
                        <Route exact path={"/"} component={Main} />
                        <Route path={"/about"} component={About} />
                        <Route path={"/blog"} component={Blog} />
                        <Route path={"/realEstate"} component={RealEstate} />
                        <Route path={"/palmdor"} component={Palmdor} />
                        <Route path={"/bps"} component={Bps} />
                        <Route path={"/hornsey"} component={Hornsey} />
                        <Route path={"/luton"} component={Luton} />
                        <Route path={"/consultation"} component={Consultation} />
                        <Route path={"/education"} component={Education} />
                        <Route path={"/visa&immigration"} component={VisaAndImmigration} />
                        <Route path={"/student_visa"} component={StudentVisa} />
                        <Route path={"/general_visa"} component={GeneralVisa} />
                        <Route path={"/settlement_visa"} component={SettlementVisa} />
                        <Route path={"/english_language"} component={EnglishLanguage} />
                        <Route path={"/university"} component={University} />
                        <Route path={"/training_courses"} component={TrainingCourse} />
                        <Route path={"/hashloops"} component={Hashloops}/>
                        <Route path={"/contact"} component={Contact} />
                    </Route>
                </Layouts>
            </Router>
        );
    }
}

export default Master;