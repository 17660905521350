import React, {Component} from 'react';

class About extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/about.jpg)`, backgroundPosition: 'center'}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>About Us</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-details mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="offset-xl-1 col-lg-10">
                                    <p>Leading consultants with presence in the United Kingdom and Kingdom of Saudi Arabia. We have been proudly offering services to our clients who fly to the United Kingdom for English Language Courses, joining university or for tourism purposes. Our assistance has helped our clients in making the right decision and choice</p>

                                    {/*<p>Our assistance has helped our clients in making the right decision and choice</p>*/}
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="offset-xl-1 col-lg-8">
                                    <div className="about-details-cap mb-50">
                                        <h4>Our Mission</h4>
                                        <p>Our mission is to provide the best services to our clients up to their expectations and satisfaction.</p>
                                        {/*<p>In 1990, ConsultingWP in conjunction with Inception Analysis was incorporated to strengthen its expertise in the field of energy and opened its first office in Cupertino. Since then, the company has expanded across the United States and Europe to more than 300 people.</p>*/}

                                        {/*<p>We know customer brands inside and out to create bespoke campaigns that bring the brand story to life, engage with consumers, and inspire communication.</p>*/}

                                        {/*<div className="row">*/}
                                        {/*    <div className="col-md-6">*/}
                                        {/*        <div>*/}
                                        {/*            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>You can do more in less time</p>*/}
                                        {/*            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Work less hours &amp; Make money</p>*/}
                                        {/*            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Our phenomenal success</p>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="col-md-6">*/}
                                        {/*        <div>*/}
                                        {/*            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Leadership skills to manage a team</p>*/}
                                        {/*            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Keep paying customers</p>*/}
                                        {/*            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Making millions of dollars</p>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>

                                    {/*<div className="about-details-cap mb-50">*/}
                                    {/*    <h4>Our Vision</h4>*/}
                                    {/*    <p>Consectetur adipiscing elit, sued do eiusmod tempor ididunt udfgt labore et*/}
                                    {/*        dolore magna aliqua. Quis ipsum suspendisces gravida. Risus commodo viverra*/}
                                    {/*        sebfd dho eiusmod tempor maecenas accumsan lacus. Risus commodo viverra*/}
                                    {/*        sebfd dho eiusmod tempor maecenas accumsan lacus.*/}
                                    {/*    </p>*/}
                                    {/*    <p> Risus commodo viverra sebfd dho eiusmod tempor maecenas accumsan lacus.*/}
                                    {/*        Risus commodo viverra sebfd dho eiusmod tempor maecenas accumsan.</p>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="support-company-area pt-100 pb-100 section-bg fix"
                         style={{backgroundImage: `url(assets/img/gallery/section_bg02.jpg)`}}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="support-location-img">
                                        <img src="assets/img/ourServices.jpg" alt=""/>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="right-caption">
                                        <div className="section-tittle section-tittle2 mb-50">
                                            <span>Our Top Services</span>
                                            <h2>Our Best Services</h2>
                                        </div>
                                        <div className="support-caption">
                                            <p className="pera-top">Buckingham Consultancy provides the best services all over the UK, which includes:</p>
                                            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Education</p>
                                            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Real Estate</p>
                                            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Consultation</p>
                                            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Visa & Immigration</p>
                                            <p><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>IT Services & Solutions</p>
                                            {/*<a href="about.html" className="btn post-btn">More About Us</a>*/}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="team-area section-padding30 pb-3">
                        <div className="container">
                            <div className="row">
                                <div className="cl-xl-7 col-lg-8 col-md-10">
                                    <div className="section-tittle mb-70">
                                        <span>Our Professional Partners </span>
                                        <h2>Our Partners</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-">
                                    <div className="single-team mb-30">
                                        <div className="team-img text-center">
                                            <img src="assets/img/hashloops.png" alt="" style={{width: 200}}/>
                                        </div>
                                        {/*<div className="team-caption">*/}
                                        {/*    <h3><a href="#">Hashloops Technologies</a></h3>*/}
                                        {/*    <span>IT Services & Solutions</span>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-">
                                    <div className="single-team mb-30">
                                        <div className="team-img text-center">
                                            <img src="assets/img/welcomedeedBlack.jpeg" alt="" style={{width: 190}}/>
                                        </div>
                                        {/*<div className="team-caption">*/}
                                        {/*    <h3><a href="#">Dar Al Faisal</a></h3>*/}
                                        {/*    <span>Tourism</span>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-">
                                    <div className="single-team mb-30">
                                        <div className="team-img text-center">
                                            <img src="assets/img/daralfaisal.png" alt="" style={{width: 210}}/>
                                        </div>
                                        {/*<div className="team-caption">*/}
                                        {/*    <h3><a href="#">Dar Al Faisal</a></h3>*/}
                                        {/*    <span>Tourism</span>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-">
                                    <div className="single-team mb-30">
                                        <div className="team-img text-center">
                                            <img src="assets/img/fursan.jpeg" alt="" style={{width: 210}} className="mt-2"/>
                                        </div>
                                        {/*<div className="team-caption">*/}
                                        {/*    <h3><a href="#">Dar Al Faisal</a></h3>*/}
                                        {/*    <span>Tourism</span>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-">
                                    <div className="single-team mb-30">
                                        <div className="team-img text-center">
                                            <img src="assets/img/aias.jpeg" alt="" style={{width: 125}}/>
                                        </div>
                                        {/*<div className="team-caption">*/}
                                        {/*    <h3><a href="#">Dar Al Faisal</a></h3>*/}
                                        {/*    <span>Tourism</span>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="testimonial-area testimonial-padding"*/}
                    {/*     data-background="assets/img/gallery/section_bg04.jpg">*/}
                    {/*    <div className="container ">*/}
                    {/*        <div className="row d-flex justify-content-center">*/}
                    {/*            <div className="col-xl-10 col-lg-10 col-md-9">*/}
                    {/*                <div className="h1-testimonial-active">*/}
                    {/*                    <div className="single-testimonial text-center">*/}
                    {/*                        <div className="testimonial-caption ">*/}
                    {/*                            <div className="testimonial-top-cap">*/}
                    {/*                                <svg xmlns="http://www.w3.org/2000/svg"*/}
                    {/*                                     xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                    {/*                                     width="67px" height="49px">*/}
                    {/*                                    <path fill-rule="evenodd" fill="rgb(240, 78, 60)"*/}
                    {/*                                          d="M57.053,48.209 L42.790,48.209 L52.299,29.242 L38.036,29.242 L38.036,0.790 L66.562,0.790 L66.562,29.242 L57.053,48.209 ZM4.755,48.209 L14.263,29.242 L0.000,29.242 L0.000,0.790 L28.527,0.790 L28.527,29.242 L19.018,48.209 L4.755,48.209 Z"/>*/}
                    {/*                                </svg>*/}
                    {/*                                <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut*/}
                    {/*                                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices*/}
                    {/*                                    gravida. Risus commodo viverra maecenas accumsan lacus vel*/}
                    {/*                                    facilisis por incididunt ut labore et dolore mas. </p>*/}
                    {/*                            </div>*/}
                    {/*                            <div*/}
                    {/*                                className="testimonial-founder d-flex align-items-center justify-content-center">*/}
                    {/*                                <div className="founder-img">*/}
                    {/*                                    <img src="assets/img/gallery/Homepage_testi.png" alt=""/>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="founder-text">*/}
                    {/*                                    <span>Jessya Inn</span>*/}
                    {/*                                    <p>Chif Photographer</p>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className="single-testimonial text-center">*/}
                    {/*                        <div className="testimonial-caption ">*/}
                    {/*                            <div className="testimonial-top-cap">*/}
                    {/*                                <svg xmlns="http://www.w3.org/2000/svg"*/}
                    {/*                                     xmlnsXlink="http://www.w3.org/1999/xlink"*/}
                    {/*                                     width="67px" height="49px">*/}
                    {/*                                    <path fill-rule="evenodd" fill="rgb(240, 78, 60)"*/}
                    {/*                                          d="M57.053,48.209 L42.790,48.209 L52.299,29.242 L38.036,29.242 L38.036,0.790 L66.562,0.790 L66.562,29.242 L57.053,48.209 ZM4.755,48.209 L14.263,29.242 L0.000,29.242 L0.000,0.790 L28.527,0.790 L28.527,29.242 L19.018,48.209 L4.755,48.209 Z"/>*/}
                    {/*                                </svg>*/}
                    {/*                                <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut*/}
                    {/*                                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices*/}
                    {/*                                    gravida. Risus commodo viverra maecenas accumsan lacus vel*/}
                    {/*                                    facilisis por incididunt ut labore et dolore mas. </p>*/}
                    {/*                            </div>*/}
                    {/*                            <div*/}
                    {/*                                className="testimonial-founder d-flex align-items-center justify-content-center">*/}
                    {/*                                <div className="founder-img">*/}
                    {/*                                    <img src="assets/img/gallery/Homepage_testi.png" alt=""/>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="founder-text">*/}
                    {/*                                    <span>Jessya Inn</span>*/}
                    {/*                                    <p>Chif Photographer</p>*/}
                    {/*                                </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="count-down-area pb-120">*/}
                    {/*    <div className="container">*/}
                    {/*        <div className="row justify-content-between">*/}
                    {/*            <div className="col-lg-3 col-md-6 col-sm-6">*/}
                    {/*                <div className="single-counter text-center">*/}
                    {/*                    <span className="counter">8705</span>*/}
                    {/*                    <p>Projects Completed</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-lg-3 col-md-6 col-sm-6">*/}
                    {/*                <div className="single-counter active text-center">*/}
                    {/*                    <span className="counter">480</span>*/}
                    {/*                    <p> Active Clients</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-lg-3 col-md-6 col-sm-6">*/}
                    {/*                <div className="single-counter text-center">*/}
                    {/*                    <span className="counter">626</span>*/}
                    {/*                    <p>Cups of Coffee</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="col-lg-3 col-md-6 col-sm-6">*/}
                    {/*                <div className="single-counter text-center">*/}
                    {/*                    <span className="counter">9774</span>*/}
                    {/*                    <p>Happy Clients</p>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="brand-area pt-150 pb-140">*/}
                    {/*    <div className="container">*/}
                    {/*        <div className="brand-active brand-border pb-40">*/}
                    {/*            <div className="single-brand">*/}
                    {/*                <img src="assets/img/gallery/brand1.png" alt=""/>*/}
                    {/*            </div>*/}
                    {/*            <div className="single-brand">*/}
                    {/*                <img src="assets/img/gallery/brand2.png" alt=""/>*/}
                    {/*            </div>*/}
                    {/*            <div className="single-brand">*/}
                    {/*                <img src="assets/img/gallery/brand3.png" alt=""/>*/}
                    {/*            </div>*/}
                    {/*            <div className="single-brand">*/}
                    {/*                <img src="assets/img/gallery/brand4.png" alt=""/>*/}
                    {/*            </div>*/}
                    {/*            <div className="single-brand">*/}
                    {/*                <img src="assets/img/gallery/brand2.png" alt=""/>*/}
                    {/*            </div>*/}
                    {/*            <div className="single-brand">*/}
                    {/*                <img src="assets/img/gallery/brand5.png" alt=""/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </main>
            </React.Fragment>
        );
    }
}

export default About;