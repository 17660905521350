import React, {Component} from 'react';

class Luton extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/lutonMain.png)`}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>Luton <br/>مشروع استثماري شمال لندن </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="categories-area section-padding30 pb-0" id="services">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-tittle mb-70">
                                        <span>مشروع استثماري شمال لندن Luton</span>
                                        <h2>Project Details</h2>
                                    </div>
                                </div>
                            </div>

                            <p>The commanding 10 storey building has been designed to deliver contemporary homes to professional tenants working in London and Luton. Its proximity to London Luton Airport (the fifth largest UK airport and just a ten-minute drive from The Orion), means that the development will also appeal to those whose work takes them overseas regularly.
                                The Orion features quality kitchens and bathrooms as standard, along with high-specification fixtures and fittings as well as on-site parking. The apartments appeal both to modern renters and to investors looking for a residential property with a strong investment case. Additional
                                features at The Orion include smart technology and on-site parking.</p>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon mb-3">
                                            {/*<span className="flaticon-development"></span>*/}
                                            <img src="assets/img/lutonProject1.png" className="w-100" style={{height: 220}} alt=""/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5 className="m-0"><a href="assets/pdf/luton1.pdf" target="_blank" className="m-0">Project Detail 1</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon mb-3">
                                            {/*<span className="flaticon-development"></span>*/}
                                            <img src="assets/img/lutonProject2.png" className="w-100" style={{height: 220}} alt=""/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5 className="m-0"><a href="assets/pdf/luton2.pdf" target="_blank" className="m-0">Project Detail 2</a></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default Luton;