import React, {Component} from 'react';
import {Link} from "react-router-dom";

class VisaAndImmigration extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <div className="categories-area section-padding30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-tittle mb-70">
                                    <span>Visa & Immigration</span>
                                    <h2>Services</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon mb-3">
                                        {/*<span className="flaticon-development"></span>*/}
                                        <img src="assets/img/passport.jpeg" className="w-100" style={{height: 220}} alt=""/>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><Link to={"/generalVisa"}>General Visa </Link></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon mb-3">
                                        {/*<span className="flaticon-result"></span>*/}
                                        <img src="assets/img/generalVisa.jpg" className="w-100" style={{height: 220}} alt=""/>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><Link to={"/settlementVisa"}>Settlement Visa</Link></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon mb-3">
                                        {/*<span className="flaticon-team"></span>*/}
                                        <img src="assets/img/studentVisa.jpg" className="w-100" style={{height: 220}} alt=""/>
                                    </div>
                                    <div className="cat-cap">
                                        <h5><Link to={"/studentVisa"}>Student Visa</Link></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default VisaAndImmigration;