import React, {Component} from 'react';

class GeneralVisa extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/passport.jpeg)`, backgroundPosition: 'center'}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>General Visa</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-details mt-5">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="offset-xl-1 col-lg-8">
                                    <div className="about-details-cap mb-50">
                                        <h4>General Visa</h4>

                                        <p>The United Kingdom is one of the most visited countries in the world for various reasons. Around 166,000 Saudi Citizens visited the United Kingdom and numbers have doubled in the past 10 years
                                        Standard visitor visa which has replaced many categories can be applied if you would like to visit the United Kingdom for leisure, holiday or to see family and friends, business or to take part in sports or creative events. It can also be applied for private medical treatment All visa types mentioned below has been replaced by standard visitor visa</p>

                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Family Visitor visa</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>General Visitor visa</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Child Visitor visa</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Business Visitor visa, including visas for academics, doctors and dentists</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Sports Visitor visa</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Entertainer Visitor visa</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Prospective Entrepreneur visa</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Private Medical Treatment Visitor visa</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Approved Destination Status (ADS) visa</p>

                                        <p>The earliest you can apply is 3 months before traveling to the United Kingdom and once granted you can stay up to 6 months and in case of private medical treatment, up to 11 months (Visa Fee £190)</p>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Duration</th>
                                                    <th>Fees</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>6 months</td>
                                                    <td>£95</td>
                                                </tr>
                                                <tr>
                                                    <td>2 Years</td>
                                                    <td>£361</td>
                                                </tr>
                                                <tr>
                                                    <td>5 Years</td>
                                                    <td>£95</td>
                                                </tr>
                                                <tr>
                                                    <td>5 Years</td>
                                                    <td>£655</td>
                                                </tr>
                                                <tr>
                                                    <td>10 Years</td>
                                                    <td>£822</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <p>Mention our service fee for each application</p>
                                        <p>Get in touch with us if you are planning to visit the United Kingdom for leisure, holiday, shopping or for anything else. Our expert staff will make sure you get the best service throughout this procedure and will do their best to make sure you get unique experience of the United Kingdom with us</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default GeneralVisa;