import React, {Component} from 'react';
import {Link} from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';

class Main extends Component {
    render() {
        return (
            <React.Fragment>
                <OwlCarousel loop margin={10} nav={false} dots={false} autoplay={true} autoplayTimeout={6000} autoplaySpeed={1000} items={1}>
                    <div className="item">
                        <div className="d-flex align-items-center" style={{height: 600, backgroundImage: `url(assets/img/hero/realEstate.png)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-7 col-md-8 offset-md-2 offset-0" style={{background: 'rgba(0,0,0,0.7)', borderRadius: 8, padding: 20}}>
                                        <div className="hero__caption">
                                        {/*<span data-animation="fadeInLeft"*/}
                                        {/*      data-delay=".1s">Committed to success</span>*/}
                                            <h1 data-animation="fadeInLeft" data-delay=".5s" style={{color: '#cf1429'}}>مشروع المجمع السكني المغرب - طنجة Palm Dor</h1>
                                            <p data-animation="fadeInLeft" data-delay=".9s" style={{color: '#fff'}}>The Palm d’or development is a project of Buckingham Consultancy Ltd., a UK based developer with successful projects in KSA and in the UK. This is a rare opportunity to purchase an apartment in this boutique development of only 39 high apartments, only moments from the ocean.</p>
                                            <div className="hero__btn" data-animation="fadeInLeft" data-delay="1.1s">
                                                <Link to={"/realEstate"} className="btn hero-btn">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="d-flex align-items-center" style={{height: 600, backgroundImage: `url(assets/img/hero/BPS.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-7 col-md-8 offset-md-2 offset-0" style={{background: 'rgba(0,0,0,0.7)', borderRadius: 8, padding: 20}}>
                                        <div className="hero__caption">
                                            {/*<span data-animation="fadeInLeft"*/}
                                            {/*      data-delay=".1s">Committed to success</span>*/}
                                            <h1 data-animation="fadeInLeft" data-delay=".5s" style={{color: '#cf1429'}}>مشروع شقق باترسي  بريطانيا - لندن Battersea
                                                </h1>
                                            <p data-animation="fadeInLeft" data-delay=".9s" style={{color: '#fff'}}>A place of power. Uniting people from far away and just around the corner. A landmark that’s a symbol of positivity. From morning light to vibrant night.</p>
                                            <div className="hero__btn" data-animation="fadeInLeft" data-delay="1.1s">
                                                <Link to={"/realEstate"} className="btn hero-btn">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="d-flex align-items-center" style={{height: 600, backgroundImage: `url(assets/img/hornsey4.png)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-7 col-md-8 offset-md-2 offset-0" style={{background: 'rgba(0,0,0,0.7)', borderRadius: 8, padding: 20}}>
                                        <div className="hero__caption">
                                            {/*<span data-animation="fadeInLeft"*/}
                                            {/*      data-delay=".1s">Committed to success</span>*/}
                                            <h1 data-animation="fadeInLeft" data-delay=".5s" style={{color: '#cf1429'}}>مشروع شقق هورنسي بريطانيا - لندن Hornsey
                                                </h1>
                                            <p data-animation="fadeInLeft" data-delay=".9s" style={{color: '#fff'}}>The Palm d’or development is a project of Buckingham Consultancy Ltd., a UK based developer with successful projects in KSA and in the UK. This is a rare opportunity to purchase an apartment in this boutique development of only 39 high apartments, only moments from the ocean.</p>
                                            <div className="hero__btn" data-animation="fadeInLeft" data-delay="1.1s">
                                                <Link to={"/realEstate"} className="btn hero-btn">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="d-flex align-items-center" style={{height: 600, backgroundImage: `url(assets/img/hero/luton.png)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-7 col-md-8 offset-md-2 offset-0" style={{background: 'rgba(0,0,0,0.7)', borderRadius: 8, padding: 20}}>
                                        <div className="hero__caption">
                                            {/*<span data-animation="fadeInLeft"*/}
                                            {/*      data-delay=".1s">Committed to success</span>*/}
                                            <h1 data-animation="fadeInLeft" data-delay=".5s" style={{color: '#cf1429'}}>مشروع استثماري شمال لندن Luton
                                            </h1>
                                            <p data-animation="fadeInLeft" data-delay=".9s" style={{color: '#fff'}}>Surrenden Invest is a London centric property investment consultancy providing private investo with opportunities in high demand growth areas.</p>
                                            <div className="hero__btn" data-animation="fadeInLeft" data-delay="1.1s">
                                                <Link to={"/luton"} className="btn hero-btn">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="d-flex align-items-center" style={{height: 600, backgroundImage: `url(assets/img/hero/slide1.png)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-7 col-md-8 offset-md-2 offset-0" style={{background: 'rgba(0,0,0,0.7)', borderRadius: 8, padding: 20}}>
                                        <div className="hero__caption">
                                            {/*<span data-animation="fadeInLeft"*/}
                                            {/*      data-delay=".1s">Committed to success</span>*/}
                                            <h1 data-animation="fadeInLeft" data-delay=".5s" style={{color: '#cf1429'}}>IT Services & Solutions</h1>
                                            <p data-animation="fadeInLeft" data-delay=".9s" style={{color: '#fff'}}>Buckingham Consultancy is also providing services related to IT & Softwares. The services include Website Development, Mobile Application Development, Search Engine Optimization, Social Media Marketing, Graphic Designing, 3D Designing & Modeling and Automation.</p>
                                            <div className="hero__btn" data-animation="fadeInLeft" data-delay="1.1s">
                                                <Link to={"/hashloops"} className="btn hero-btn">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="d-flex align-items-center" style={{height: 600, backgroundImage: `url(assets/img/hero/slide2.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-7 col-md-8 offset-md-2 offset-0" style={{background: 'rgba(0,0,0,0.7)', borderRadius: 8, padding: 20}}>
                                        <div className="hero__caption">
                                            {/*<span data-animation="fadeInLeft"*/}
                                            {/*      data-delay=".1s">Committed to success</span>*/}
                                            <h1 data-animation="fadeInLeft" data-delay=".5s" style={{color: '#cf1429'}}>Why Choose Universities of United Kingdom?</h1>
                                            <p data-animation="fadeInLeft" data-delay=".9s" style={{color: '#fff'}}>The education system in the UK is considered one of the oldest in the world and joining a university in the UK is a great way of expanding the knowledge, meeting new people, experience a new culture and new experiences.</p>
                                            <div className="hero__btn" data-animation="fadeInLeft" data-delay="1.1s">
                                                <Link to={"/education"} className="btn hero-btn">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="d-flex align-items-center" style={{height: 600, backgroundImage: `url(assets/img/hero/slide4.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-7 col-md-8 offset-md-2 offset-0" style={{background: 'rgba(0,0,0,0.7)', borderRadius: 8, padding: 20}}>
                                        <div className="hero__caption">
                                            {/*<span data-animation="fadeInLeft"*/}
                                            {/*      data-delay=".1s">Committed to success</span>*/}
                                            <h1 data-animation="fadeInLeft" data-delay=".5s" style={{color: '#cf1429'}}>Consultation</h1>
                                            <p data-animation="fadeInLeft" data-delay=".9s" style={{color: '#fff'}}>Buckingham Consultancy provides consultation related to Visa & Immigration, Admissions in universities of UK, IT & Softwares and Real Estate.</p>
                                            <div className="hero__btn" data-animation="fadeInLeft" data-delay="1.1s">
                                                <Link to={"/consultation"} className="btn hero-btn">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>

                <div className="about-details mt-5">
                    <div className="container">
                        <div className="row mt-5">
                            <div className="offset-xl-1 col-lg-8">
                                <div className="about-details-cap mb-50">
                                    <h4>Our Mission</h4>
                                    <p>Our mission is to provide the best services to our clients up to their expectations and satisfaction.</p>
                                    <div className="hero__btn" data-animation="fadeInLeft" data-delay="1.1s">
                                        <Link to={"/about"} className="btn hero-btn">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-area2" style={{backgroundImage: `url(assets/img/whyUs.jpg)`, backgroundPosition: 'center'}}>
                    <div className="slider-height2 hero-overly2 d-flex pt-5 whyUs" style={{ height: 500}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="hero-cap hero-cap2 text-center">
                                        <h2 style={{ display: 'inline-block', borderBottom: '4px solid #ff2143'}}>Why Us</h2>
                                    </div>
                                </div>
                            </div>

                            <div className="categories-area row mt-5" style={{backgroundColor: 'transparent'}}>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50 noBorder" style={{height: 270, border: 'none'}}>
                                        <div className="cat-icon mb-3">
                                            <p className="pt-4 whyUs">You are guided by our team right through from the first stage of process till the end. We make sure that you have chose the right course and institute, right accommodation and supported during your stay</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50 noBorder" style={{height: 270, border: 'none'}}>
                                        <div className="cat-icon mb-3">
                                            <p className="pt-4 whyUs">Our presence in both, The United Kingdom and Kingdom of Saudi Arabia makes us easily approachable and we are always available whenever you need to talk to us about any aspect of your stay</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50 noBorder" style={{height: 270, border: 'none'}}>
                                        <div className="cat-icon mb-3">
                                            <p className="pt-5 whyUs">Our team is determined and focused and have served thousands of clients with prioritising their need and satisfaction</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="categories-area section-padding30 pb-0" id="services">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-tittle mb-70">
                                    <span>Our Top Services</span>
                                    <h2>Our Best Services</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon mb-3">
                                        {/*<span className="flaticon-development"></span>*/}
                                        <img src="assets/img/education.jpg" className="w-100" style={{height: 220}} alt=""/>
                                    </div>
                                    <div className="cat-cap">
                                        <h5 className="m-0"><Link to={"/education"} className="m-0">Education </Link></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon mb-3">
                                        {/*<span className="flaticon-result"></span>*/}
                                        <img src="assets/img/education/realestate.jpg" className="w-100" style={{height: 220}} alt=""/>
                                    </div>
                                    <div className="cat-cap">
                                        <h5 className="m-0"><Link to={"/realEstate"} className="m-0">Real Estate</Link></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon mb-3">
                                        {/*<span className="flaticon-team"></span>*/}
                                        <img src="assets/img/education/consult3.jpg" className="w-100" style={{height: 220}} alt=""/>
                                    </div>
                                    <div className="cat-cap">
                                        <h5 className="m-0"><Link to={"/consultation"} className="m-0">Consultation</Link></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 offset-md-2">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon mb-3">
                                        {/*<span className="flaticon-team"></span>*/}
                                        <img src="assets/img/passport.jpeg" className="w-100" style={{height: 220}} alt=""/>
                                    </div>
                                    <div className="cat-cap">
                                        <h5 className="m-0"><Link to={"/visa&immigration"} className="m-0">Visa & Immigration</Link></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-cat text-center mb-50">
                                    <div className="cat-icon mb-3">
                                        {/*<span className="flaticon-team"></span>*/}
                                        <img src="assets/img/ITServices.png" className="w-100" style={{height: 220}} alt=""/>
                                    </div>
                                    <div className="cat-cap">
                                        <h5 className="m-0"><Link to={"/hashloops"} className="m-0">IT Services & Solutions</Link></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;