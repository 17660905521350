import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer>
                    <div className="footer-area section-bg" style={{backgroundImage: `url(assets/img/gallery/footer_bg.jpg)`}}>
                        <div className="container">
                            <div className="footer-top footer-padding">
                                <div className="row d-flex justify-content-between">
                                    <div className="col-xl-3 col-lg-4 col-md-5 col-sm-8">
                                        <div className="single-footer-caption mb-50">
                                            {/*<div className="footer-logo" style={{width: 400, background: 'white'}}>*/}
                                            {/*    <a href="index.html">*/}
                                            {/*        <img src="assets/img/flag.png" alt="" className="d-inline-block img1" style={{width: '30%'}}/>*/}
                                            {/*        <img src="assets/img/BC1.png" alt="" className="d-inline-block ml-1 img2" style={{width: '69%'}}/>*/}
                                            {/*    </a>*/}
                                            {/*</div>*/}
                                            <div className="footer-tittle">
                                                <div className="footer-pera">
                                                    {/*<p className="info1">Receive updates and latest news direct from*/}
                                                    {/*    Simply enter.</p>*/}
                                                    <h4 className="mb-2">Contact Us</h4>
                                                </div>
                                            </div>
                                            <div className="footer-number ml-2 mt-4">
                                                <h5 className="text-white" style={{fontSize: 18, fontWeight: '100'}}>United Kingdom - London</h5>
                                                <h6 className="ml-3"><span style={{ color: '#fff'}}>Tel:</span><span style={{ color: '#fff'}} className="ml-3">+44 </span> <span style={{color: '#ffffff'}}>20 81919999</span></h6>
                                                <h6 className="ml-3"><span style={{ color: '#fff'}}>Mob:</span><span style={{ color: '#fff'}}> +44 </span> <span style={{color: '#ffffff'}}>73782 99999</span></h6>
                                                <h6 className="ml-3"><span style={{ color: '#fff'}}>Mob:</span><span style={{ color: '#fff'}}> +44 </span> <span style={{color: '#ffffff'}}>73782 77777</span></h6>
                                                <h6 className="ml-3"><span style={{ color: '#fff'}}>Mob:</span><span style={{ color: '#fff'}}> +44 </span> <span style={{color: '#ffffff'}}>74717 66666</span></h6>
                                                <h5 className="text-white mt-4" style={{fontSize: 18, fontWeight: '100'}}>Saudi Arabia - Jeddah</h5>
                                                <h6 className="ml-3"><span style={{ color: '#fff'}}>Mob:</span><span style={{ color: '#fff'}}> +966 </span> <span style={{color: '#ffffff'}}>5406 22222</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-5">
                                        <div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Email Us:</h4>
                                                <div className="footer-pera">
                                                    <p className="info1">buckinghamconsultancy@gmail.com</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="col-xl-2 col-lg-2 col-md-3 col-sm-5">*/}
                                    {/*    <div className="single-footer-caption mb-50">*/}
                                    {/*        <div className="footer-tittle">*/}
                                    {/*            <h4>Explore</h4>*/}
                                    {/*            <ul>*/}
                                    {/*                <li><a href="#">Cookies</a></li>*/}
                                    {/*                <li><a href="#">About</a></li>*/}
                                    {/*                <li><a href="#">Privacy Policy</a></li>*/}
                                    {/*                <li><a href="#">Proparties</a></li>*/}
                                    {/*                <li><a href="#">Licenses</a></li>*/}
                                    {/*            </ul>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-8">
                                        {/*<div className="single-footer-caption mb-50">
                                            <div className="footer-tittle">
                                                <h4>Subscribe</h4>
                                                <div className="footer-pera">
                                                    <p className="info1">Subscribe now to get daily updates</p>
                                                </div>
                                            </div>
                                            <div className="footer-form">
                                                <div id="mc_embed_signup">
                                                    <form target="_blank"
                                                          action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                                                          method="get" className="subscribe_form relative mail_part"
                                                          noValidate="true">
                                                        <input type="email" name="EMAIL" id="newsletter-form-email"
                                                               placeholder=" Email Address "
                                                               className="placeholder hide-on-focus"
                                                               onFocus="this.placeholder = ''"
                                                               onBlur="this.placeholder = 'Your email address'"/>
                                                            <div className="form-icon">
                                                                <button type="submit" name="submit"
                                                                        id="newsletter-submit"
                                                                        className="email_icon newsletter-submit button-contactForm">
                                                                    SIGN UP
                                                                </button>
                                                            </div>
                                                            <div className="mt-10 info"></div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="footer-bottom">
                                <div className="row d-flex justify-content-between align-items-center">
                                    <div className="col-xl-9 col-lg-8">
                                        <div className="footer-copy-right">
                                            <p className="m-0">
                                                Copyright &copy;
                                                {new Date().getFullYear()} &nbsp;
                                                All Rights are Reserved by Buckingham Consultancy.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-4">
                                        <div className="footer-social f-right">
                                            <a href="#"><i className="fab fa-twitter"></i></a>
                                            <a href="https://www.facebook.com/sai4ull"><i
                                                className="fab fa-facebook-f"></i></a>
                                            <a href="#"><i className="fas fa-globe"></i></a>
                                            <a href="#"><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div id="back-top">
                    <a title="Go to Top" href="#"> <i className="fas fa-level-up-alt"></i></a>
                </div>
            </React.Fragment>
        );
    }
}

export default Footer;