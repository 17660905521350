import React, {Component} from 'react';

class Palmdor extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/palmdor.jpeg)`}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>Palm Dor <br/>مشروع المجمع السكني
                                                <br/>  المغرب - طنجة </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="categories-area section-padding30 pb-0" id="services">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-tittle mb-70">
                                        <span>مشروع المجمع السكني Palm Dor</span>
                                        <h2>Project Details</h2>
                                    </div>
                                </div>
                            </div>

                            <p>The site is located 12 km west of
                                Tangier in Cap Spartel, the north-western extremity of Africa's Atlantic
                                coast less than 500m from the Kings
                                palace, and only a hundred steps from
                                soft powder sandy beaches overlooking from magnificent Atlantic Ocean. The 20
                                minute drive from Tangier city centre to the area of Achakara is truly magnificent
                                passing ancient pine forests and then
                                following the stunning coastal road. Tangiers International Airport is only 5 miles away from the Palm d’or.</p>
                            <p>
                                The Palm d’or is a beautifully planned
                                development incorporating classical
                                Moroccan design features such as
                                terracotta tiles, arches and traditional wooden verandas. Artisans from the historic city of Fez will be employed to
                                add that classical touch.
                                This court yard development has views over the Atlantic Ocean, rolling hills and overlooks the swimming pool and
                                traditional water gardens.
                            </p>
                            <h5>Features include:</h5>
                            <p className="ml-3"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Underground allocated parking.</p>
                            <p className="ml-3"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Beautifully designed bedrooms & bedrooms with fitted wardrobes.</p>
                            <p className="ml-3"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>WIFI</p>
                            <p className="ml-3"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Branded white goods, modern kitchen units with granite worktop.</p>
                            <p className="ml-3"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Communal swimming pool & residents gardens.</p>
                            <p className="ml-3"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Ground floor offices, cafes & shops.</p>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon mb-3">
                                            {/*<span className="flaticon-development"></span>*/}
                                            <img src="assets/img/realEstateBack.jpeg" className="w-100" style={{height: 220}} alt=""/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5 className="m-0"><a href="assets/pdf/projectDetail1.pdf" target="_blank" className="m-0">Project Detail 1</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="single-cat text-center mb-50">
                                        <div className="cat-icon mb-3">
                                            {/*<span className="flaticon-development"></span>*/}
                                            <img src="assets/img/realEstate.jpg" className="w-100" style={{height: 220}} alt=""/>
                                        </div>
                                        <div className="cat-cap">
                                            <h5 className="m-0"><a href="assets/pdf/projectDetail2.pdf" target="_blank" className="m-0">Project Detail 2</a></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default Palmdor;