import React, {Component} from 'react';

class SettlementVisa extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/generalVisa.jpg)`, backgroundPosition: 'center'}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>Settlement Visa</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-details mt-5">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="offset-xl-1 col-lg-8">
                                    <div className="about-details-cap mb-50">
                                        <h4>Settlement Visa</h4>

                                        <p>Spouse / Civil Partner Visa also known as spouse visa, allows married partners of UK citizens to immigrate to the United Kingdom. The spouse or civil partner must have no immigration restrictions on how long they can stay in the United Kingdom</p>

                                        <p>The requirement in order to apply for Spouse/Civil Partner visa are:</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>You and your partner must be 18 years or older</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Both partners must have met before and legally married or in civil partnership agreement</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Must intend to live together permanently</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Enough funds to support yourselves and if there are any dependents with claiming public funds</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Sponsoring must earn £18,600 per year or should have enough savings to sponsor you. In case of dependents, the financial requirements are higher</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Must have accommodation for yourself and sponsor</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Must meet the English language requirements</p>

                                        <p>The visa can be applied from overseas or from within in the United Kingdom. The process can take from 2-12 weeks, depending on country you are applying from and can take 8 weeks or longer from within the United Kingdom. The visa is initially issued for 33 months if applied overseas and from within the United Kingdom for 30 months. Once the duration of visa is finished, you can apply for extension which is another 30 months and if granted, you can apply for Indefinite Leave to Remain also known as ILR after completing 5 years. Once ILR granted, you may be eligible for British citizenship
                                            Get in touch with us if you are planning to apply for Spouse/Civil Partner visa from Kingdom of Saudi Arabia and GCC countries. We can assist all nationalities in this category and even those who do not meet the requirements but are married to British citizens and have children together
                                            We can also assist anyone applying from Islamic Republic of Pakistan</p>

                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Visa Type</th>
                                                <th>Fees</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Joining Partner</td>
                                                <td>£1,523 / £1,033 (within UK)</td>
                                            </tr>
                                            <tr>
                                                <td>Each Dependant on Application</td>
                                                <td>£1,523 Each / £1,033 (within UK)</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <p>Parents and Child can also apply for Family Visa in order to join their family in the United Kingdom. Applicant might have to pay health surcharge as a part of the application</p>

                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Visa Type</th>
                                                <th>Fees</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Joining Parent or Child</td>
                                                <td>£1,523 / £1,033 (within UK)</td>
                                            </tr>
                                            <tr>
                                                <td>Adults who need to be looked after</td>
                                                <td>£3,250 / £1,033 (within UK)</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default SettlementVisa;