import React, {Component} from 'react';

class Contact extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/contact.jpg)`, backgroundPosition: 'center'}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>Contact US</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="contact-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="contact-title">Get in Touch</h2>
                                </div>
                                <div className="col-lg-8">
                                    <form className="form-contact contact_form" id="contactForm" noValidate="novalidate">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <textarea className="form-control w-100" name="message" id="message"
                                                              cols="30" rows="9" onFocus="this.placeholder = ''"
                                                              onBlur="this.placeholder = 'Enter Message'"
                                                              placeholder=" Enter Message"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input className="form-control valid" name="name" id="name"
                                                           type="text" onFocus="this.placeholder = ''"
                                                           onBlur="this.placeholder = 'Enter your name'"
                                                           placeholder="Enter your name"/>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input className="form-control valid" name="email" id="email"
                                                           type="email" onFocus="this.placeholder = ''"
                                                           onBlur="this.placeholder = 'Enter email address'"
                                                           placeholder="Email"/>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input className="form-control" name="subject" id="subject"
                                                           type="text" onFocus="this.placeholder = ''"
                                                           onBlur="this.placeholder = 'Enter Subject'"
                                                           placeholder="Enter Subject"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <button type="submit" className="button button-contactForm boxed-btn">Send
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-3 offset-lg-1">
                                    <div className="media contact-info">
                                        <span className="contact-info__icon"><i className="ti-home"></i></span>
                                        <div className="media-body">
                                            <h3>205 Pentax House South Hill Avenue, Harrow,</h3>
                                            <h3> United Kingdom,</h3>
                                            <h3> HA2 0DU</h3>
                                        </div>
                                    </div>
                                    <div className="media contact-info">
                                        <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                                        <div className="media-body">
                                            <h3>+44 (0)2081919999</h3>
                                        </div>
                                    </div>
                                    <div className="media contact-info">
                                        <span className="contact-info__icon"><i className="ti-email"></i></span>
                                        <div className="media-body">
                                            <h3>buckinghamconsultancy@gmail.com</h3>
                                            <p>Send us your query anytime!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </React.Fragment>
        );
    }
}

export default Contact;