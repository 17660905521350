import React, {Component} from 'react';

class University extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/university.jpg)`, backgroundPosition: 'center'}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>University</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-details mt-5">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="offset-xl-1 col-lg-8">
                                    <div className="about-details-cap mb-50">
                                        <h4>University</h4>

                                        <h5>Why Choose the United Kingdom?</h5>

                                        <p>The education system in the UK is considered one of the oldest in the world and joining a university in the UK is a great way of expanding the knowledge, meeting new people, experience a new culture and new experiences. England, Scotland, Wales, and Northern Ireland make up the United Kingdom and this makes it a great place to live and study as it is very diverse and multicultural society with rich cultures, languages and faiths </p>

                                        <h5>Benefits of Joining University in the UK</h5>

                                        <p><span style={{fontWeight: 'bold'}}>High Quality Education:</span> UK universities are well known for their high quality and up to date education. There are wide ranges of courses available and UK universities do make a good position due to their outstanding research facilities. Universities in the UK are also well known for their modern and extensive libraries, laboratories, and some with their own museum’s </p>
                                        <p><span style={{fontWeight: 'bold'}}>Courses Availability:</span>  There are large number of higher education institutes in the UK and each of them have something to offer to international students. All universities in the UK offer wide range of undergraduate and postgraduate courses and degrees. It is easy for international student to find a course of their interest </p>
                                        <p><span style={{fontWeight: 'bold'}}>Learning and Improving Skills:</span>  The age we are living in is incredibly competitive and special talent is required in order to be successful. Employers these days have higher expectations from their employees containing critical, effective, and creative thinking. Employers are also looking for candidates who are fluent in English and there is no other better place to learn English in its native country</p>
                                        <p><span style={{fontWeight: 'bold'}}>Large Student Community:</span>  The UK welcomes 500,000 international students every year as it is one of the most popular education destinations for international students. Students get warm welcome from the time they start applying and throughout achieving the degree. Studying in the UK also allows a student to experience multicultural environment, making new friends and meet new people from across the globe and benefit from learning and enhancing range of skills which are essential in today’s global environment </p>
                                        <p><span style={{fontWeight: 'bold'}}>Working While Study:</span>  International students are allowed to work par time in the UK in order to gain experience and improve their language and communication skills while studying. It is easy to find a job in the UK as it is 6th largest economy in the world and student’s can easily benefit from working in the UK as they can add this experience in their resume (CV) </p>
                                        <p><span style={{fontWeight: 'bold'}}>International Recognition of Degree:</span>  Completing a degree in the UK university will not enhance your skills or gain advanced knowledge or be expert in your field but it will also be recognized internationally. UK universities are well known for their excellent academic records and studying in the UK will boost your career whether you want to join multinational company, government or private sector</p>
                                        <p><span style={{fontWeight: 'bold'}}>English Language Skills:</span>  In this age, communication is vital and English language is internationally as a mean of communication. The UK is where English language was born thus it makes it the best place to learn English for people from different backgrounds</p>

                                        <h5>English Language Skills:</h5>

                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Choosing the right location and college</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Visa assistance and procedures</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Pre and Post departure assistance such as transport and accommodation</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Our team in London is only one call away if you require any assistance or support</p>

                                        <p>Our team in London is only one call away if you require any assistance or support</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default University;