import React, {Component} from 'react';
import {Link} from "react-router-dom";

class Header extends Component {
    constructor() {
        super();

        this.state = {
            numbers: false,
            page: null,
            mainMenu: false,
            subMenu: false,
            open: false,
            subSubMenu: false,
            subOpen: false,
            imiMenu: false,
            imiOpen: false,
            consMenu: false,
            consOpen: false,
            realMenu: false,
            realOpen: false
        }

        this.showNumbers = this.showNumbers.bind(this);
    }

    showNumbers = () => {
        this.setState({
            numbers: !this.state.numbers
        });
    }

    render() {
        return (
            <React.Fragment>
                <header>
                    <div className="header-area">
                        <div className="main-header ">
                            <div className="header-top d-none d-lg-block">
                                <div className="container">
                                    <div className="col-xl-12">
                                        <div className="row d-flex justify-content-between align-items-center">
                                            <div className="header-info-left">
                                                <ul>
                                                    <li><i className="far fa-clock"></i> Mon - SAT: 9.00 am - 5.00 pm
                                                    </li>
                                                    <li>Sun: Closed</li>
                                                </ul>
                                            </div>
                                            <div className="header-info-right">
                                                <ul className="header-social">
                                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="header-bottom  header-sticky">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-xl-2 col-lg-2">
                                            <div className="logo">
                                                <Link to={"/"}><img src="assets/img/logo/logo.png" alt=""/></Link>
                                            </div>
                                        </div>
                                        <div className="col-xl-10 col-lg-10">
                                            <div
                                                className="menu-wrapper  d-flex align-items-center justify-content-end">
                                                <div className="main-menu d-none d-lg-block">
                                                    <nav>
                                                        <ul id="navigation">
                                                            <li><Link to={"/"}>Home</Link></li>
                                                            <li><Link to={"/about"}>About</Link></li>
                                                            <li><Link to={"/blog"}>Blog</Link></li>
                                                            <li><a>Our Services</a>
                                                                <ul className="submenu">
                                                                    <li><Link to={"/education"}>Education</Link>
                                                                        <ul className="submenu" style={{left: 150, top: '60%'}}>
                                                                            <li><Link to={"/english_language"}>English Language</Link></li>
                                                                            <li><Link to={"/university"}>University</Link></li>
                                                                            <li><Link to={"/training_courses"}>Training Courses</Link></li>
                                                                        </ul>
                                                                    </li>
                                                                    <li><Link to={"/realEstate"}>Real Estate</Link>
                                                                        {/*<ul className="submenu" style={{left: 150, top: '60%'}}>*/}
                                                                        {/*    <li><Link to={"/education"}>Rent</Link></li>*/}
                                                                        {/*    <li><Link to={"/realEstate"}>Holiday</Link>*/}
                                                                        {/*    </li>*/}
                                                                        {/*    <li><Link to={"/consultation"}>Sale</Link></li>*/}
                                                                        {/*</ul>*/}
                                                                    </li>
                                                                    <li><Link to={"/consultation"}>Consultation</Link>
                                                                        {/*<ul className="submenu" style={{left: 150, top: '60%'}}>*/}
                                                                        {/*    <li><Link to={"/education"}>Consultation 1</Link></li>*/}
                                                                        {/*    <li><Link to={"/realEstate"}>Consultation 2</Link>*/}
                                                                        {/*    </li>*/}
                                                                        {/*    <li><Link to={"/consultation"}>Consultation 3</Link></li>*/}
                                                                        {/*</ul>*/}
                                                                    </li>
                                                                    <li><Link to={"/visa&immigration"}>Visa & Immigration</Link>
                                                                        <ul className="submenu" style={{left: 150, top: '60%'}}>
                                                                            <li><Link to={"/general_visa"}>General Visa</Link></li>
                                                                            <li><Link to={"/student_visa"}>Student Visa</Link>
                                                                            </li>
                                                                            <li><Link to={"/settlement_visa"}>Settlement Visa</Link></li>
                                                                        </ul>
                                                                    </li>
                                                                    <li><Link to={'/hashloops'}>IT Services & Solutions</Link></li>
                                                                </ul>
                                                            </li>
                                                            <li><Link to={"/contact"}>Contact</Link></li>
                                                        </ul>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mobile_menu d-block d-lg-none">
                                                <div className="slicknav_menu">
                                                    <a aria-haspopup="true" role="button" onClick={()=>this.setState({mainMenu: !this.state.mainMenu})} tabIndex="0" className="slicknav_btn slicknav_collapsed" style={{ outline: 'none'}}>
                                                        <span className="slicknav_menutxt">MENU</span>
                                                        <span className="slicknav_icon">
                                                            <span className="slicknav_icon-bar"></span>
                                                            <span className="slicknav_icon-bar"></span>
                                                            <span className="slicknav_icon-bar"></span>
                                                        </span>
                                                    </a>
                                                    {(this.state.mainMenu)? (
                                                        <ul className="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu" id="mainMenu">
                                                            <li>
                                                                <Link to={"/"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={"/about"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>About</Link>
                                                            </li>
                                                            <li>
                                                                <Link to={"/blog"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Blog</Link>
                                                            </li>
                                                            <li className="slicknav_parent slicknav_collapsed">
                                                                <a role="menuitem" aria-haspopup="true" tabIndex="-1" onClick={()=>this.setState({subMenu: !this.state.subMenu, open: !this.state.open})} className="slicknav_item slicknav_row" style={{outline: 'none'}}>
                                                                    <a tabIndex="-1">Our Services</a>
                                                                    <span className="slicknav_arrow">{(this.state.open)? "-":"+"}</span>
                                                                </a>
                                                                {(this.state.subMenu)? (
                                                                    <ul className="submenu slicknav_hidden" role="menu" aria-hidden="true">
                                                                        <li>
                                                                            <a role="menuitem" aria-haspopup="true" tabIndex="-1" onClick={()=>this.setState({subSubMenu: !this.state.subSubMenu, subOpen: !this.state.subOpen})} className="slicknav_item slicknav_row" style={{outline: 'none'}}>
                                                                                <Link to={"/education"} tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Education</Link>
                                                                                <span className="slicknav_arrow">{(this.state.subOpen)? "-":"+"}</span>
                                                                            </a>
                                                                            {(this.state.subSubMenu)? (
                                                                                <ul className="submenu slicknav_hidden" role="menu" aria-hidden="true">
                                                                                    <li>
                                                                                        <Link to={"/english_language"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>English Language</Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link to={"/university"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>University</Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link to={"/training_courses"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Training Courses</Link>
                                                                                    </li>
                                                                                </ul>
                                                                            ):(null)}
                                                                        </li>
                                                                        <li>
                                                                            <a role="menuitem" aria-haspopup="true" tabIndex="-1" className="slicknav_item slicknav_row" style={{outline: 'none'}}>
                                                                                <Link to={"/realEstate"} tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Real Estate</Link>
                                                                                {/*<span className="slicknav_arrow">{(this.state.realOpen)? "-":"+"}</span>*/}
                                                                            </a>
                                                                            {/*{(this.state.realMenu)? (*/}
                                                                            {/*    <ul className="submenu slicknav_hidden" role="menu" aria-hidden="true">*/}
                                                                            {/*        <li>*/}
                                                                            {/*            <Link to={"/english_language"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Rent</Link>*/}
                                                                            {/*        </li>*/}
                                                                            {/*        <li>*/}
                                                                            {/*            <Link to={"/university"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Holiday</Link>*/}
                                                                            {/*        </li>*/}
                                                                            {/*        <li>*/}
                                                                            {/*            <Link to={"/training_courses"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Sale</Link>*/}
                                                                            {/*        </li>*/}
                                                                            {/*    </ul>*/}
                                                                            {/*):(null)}*/}
                                                                        </li>
                                                                        <li>
                                                                            <a role="menuitem" aria-haspopup="true" tabIndex="-1" className="slicknav_item slicknav_row" style={{outline: 'none'}}>
                                                                                <Link to={"/consultation"} tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Consultation</Link>
                                                                                {/*<span className="slicknav_arrow">{(this.state.consOpen)? "-":"+"}</span>*/}
                                                                            </a>
                                                                            {/*{(this.state.consMenu)? (*/}
                                                                            {/*    <ul className="submenu slicknav_hidden" role="menu" aria-hidden="true">*/}
                                                                            {/*        <li>*/}
                                                                            {/*            <Link to={"/english_language"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Consultation 1</Link>*/}
                                                                            {/*        </li>*/}
                                                                            {/*        <li>*/}
                                                                            {/*            <Link to={"/university"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Consultation 2</Link>*/}
                                                                            {/*        </li>*/}
                                                                            {/*        <li>*/}
                                                                            {/*            <Link to={"/training_courses"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Consultation 3</Link>*/}
                                                                            {/*        </li>*/}
                                                                            {/*    </ul>*/}
                                                                            {/*):(null)}*/}
                                                                        </li>
                                                                        <li>
                                                                            <a role="menuitem" aria-haspopup="true" tabIndex="-1" onClick={()=>this.setState({imiMenu: !this.state.imiMenu, imiOpen: !this.state.imiOpen})} className="slicknav_item slicknav_row" style={{outline: 'none'}}>
                                                                                <Link to={"/visa&immigration"} tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Visa & Immigration</Link>
                                                                                <span className="slicknav_arrow">{(this.state.imiOpen)? "-":"+"}</span>
                                                                            </a>
                                                                            {(this.state.imiMenu)? (
                                                                                <ul className="submenu slicknav_hidden" role="menu" aria-hidden="true">
                                                                                    <li>
                                                                                        <Link to={"/general_visa"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>General Visa</Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link to={"/settlement_visa"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Settlement Visa</Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        <Link to={"/student_visa"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Student Visa</Link>
                                                                                    </li>
                                                                                </ul>
                                                                            ):(null)}
                                                                        </li>
                                                                        <li>
                                                                            <Link to={"/hashloops"} tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>IT Services & Solution</Link>
                                                                        </li>
                                                                    </ul>
                                                                ):(null)}
                                                            </li>
                                                            <li>
                                                                <Link to={"/contact"} role="menuitem" tabIndex="-1" onClick={()=>this.setState({mainMenu: false})}>Contact</Link>
                                                            </li>
                                                        </ul>
                                                    ):(null)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                
                <div className="whatsapp d-inline-block" style={{ position: 'fixed', bottom: 20, left: 20, zIndex: 9999999, width: 50, heigth: 50}} onClick={()=>this.showNumbers()}>
                    <img src="assets/img/logo/whatsapp.png" alt="whatsapp" className="w-100"/>
                </div>
                {(this.state.numbers)? (
                    <div className="numbers d-inline-block" style={{ position: 'fixed', bottom: 75, left: 40, zIndex: 9999999, borderRadius: 10, width: 155, padding: 15, background: 'rgba(0,0,0,0.7)'}}>
                        <a href="https://api.whatsapp.com/send?phone=+966540622222&amp;text=Hi%20There!%20I%20want%20to%20consult." className="pb-2 d-block" style={{borderBottom: '2px solid #cf1429', color: 'white'}}>+966 5406 22222</a>
                        <br/>
                        <a href="https://api.whatsapp.com/send?phone=+447378299999&amp;text=Hi%20There!%20I%20want%20to%20consult." className="pb-2 d-block" style={{borderBottom: '2px solid #cf1429', color: 'white', marginTop: '-20px'}}>+44 73782 99999</a>
                        <br/>
                        <a href="https://api.whatsapp.com/send?phone=+447378277777&amp;text=Hi%20There!%20I%20want%20to%20consult." className="pb-2 d-block" style={{borderBottom: '2px solid #cf1429', color: 'white', marginTop: '-20px'}}>+44 73782 77777</a>
                        <br/>
                        <a href="https://api.whatsapp.com/send?phone=+447471766666&amp;text=Hi%20There!%20I%20want%20to%20consult." style={{color: 'white', marginTop: '-20px'}} className="d-block">+44 74717 66666</a>
                    </div>
                ):(null)}
            </React.Fragment>
        );
    }
}

export default Header;