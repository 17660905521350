import React, {Component} from 'react';

class EnglishLanguage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <main>
                    <div className="slider-area2" style={{backgroundImage: `url(assets/img/english.jpg)`, backgroundPosition: 'bottom'}}>
                        <div className="slider-height2 hero-overly2 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="hero-cap hero-cap2 text-center">
                                            <h2>English Language</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-details mt-5">
                        <div className="container">
                            <div className="row mt-5">
                                <div className="offset-xl-1 col-lg-8">
                                    <div className="about-details-cap mb-50">
                                        <h4>English Language</h4>

                                        <h5>Why Choose The UK for English Language</h5>

                                        <p>English is one of the widely spoken language in the world and one of most influential in terms of media, education, and research. English ranks 1st as most used language on the internet and every pilot regardless of their origin must be able to communicate in English and therefore English language has become priority for so many people</p>

                                        <h5>Benefits of learning English in the UK</h5>

                                        <p><span style={{fontWeight: 'bold'}}>No Place Like Home:</span> The UK and more specifically England is the birthplace of English language and this makes the UK more attractive to learn English language. The history of English language begins when Anglo-Saxons first settled in Britain and every individual who wants to learn English, should do the same and move to the UK to learn English from its native speakers</p>
                                        <p><span style={{fontWeight: 'bold'}}>English Everywhere:</span> The UK is a very multicultural society with 37 different English dialects. The student will be able to hear and learn the actual English dialects, able to understand how locals speak to one another. This is something not learned by reading books or in a country where English is not considered as a native language</p>
                                        <p><span style={{fontWeight: 'bold'}}>History and Culture:</span> The UK is one of the most visited countries in the world and its undeniably filled with rich history and culture. By learning and studying in the UK, students will be able to learn more about rich history and culture of the UK	</p>
                                        <p><span style={{fontWeight: 'bold'}}>Improve Confidence:</span>  English is widely spoken worldwide now and once you are out of your home country or travelling, you will have to use English as median of communication. English will help you in socialising with people, making new friends and this will increase your confidence </p>
                                        <p><span style={{fontWeight: 'bold'}}>Authentic Experience:</span>  Student will have an authentic experience by learning English in the UK as they will be able to learn it directly from the public. This will improve their understanding of English language and how its spoken today’s modern English society</p>
                                        <p><span style={{fontWeight: 'bold'}}>Valued English Qualifications:</span>  The UK is well known for its education system and has one of the world’s most prestigious and successful system. Gaining a qualification from either college or university in the UK will help student in taking the right path towards successful career</p>
                                        <p><span style={{fontWeight: 'bold'}}>Place in UK University:</span>  Gaining English language qualification from the UK will help student in gaining the place in UK university. The UK is home to many well-known and respected universities such The University of Oxford, University College London and The University of Cambridge. Enrolling becomes much easier if a student have obtained an English language qualification and lived in the UK prior to applying for university</p>

                                        <h5>We can assist you in:</h5>

                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Choosing the right location and college</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Visa assistance and procedures</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Pre and Post departure assistance such as transport and accommodation</p>
                                        <p className="ml-4"><i className="fa fa-hand-point-right mr-2" style={{color: '#cf1429'}}></i>Our team in London is only one call away if you require any assistance or support</p>

                                        <p>If you are interested, then please contact us by filling the below or you can call, email or WhatsApp us and we will respond you accordingly </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default EnglishLanguage;